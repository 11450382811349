<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">{{ partnerAdmin.full_name }}</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <router-link :to="{name: 'partner-admin-edit',params: {'id': partnerAdmin.id}}" class="header-icon header-icon-2"
        v-if="hasPermission('partner_admin_update')">
        <i class="far fa-edit"></i>
    </router-link>
</app-header>
<div class="page-title-clear"></div>
<div class="page-content">


    <!------------- PARTNER ADMIN DETAIL PART START ------------->

    <div class="card card-style mt-3">
        <div class="content">


            <div class="pa-details mb-3 mx-2" v-if="partnerAdmin.email">
                <div class="pa-detailIconContainer me-3">
                    <div class="pa-detailIconWrapper">
                        <i class="fas fa-envelope fa-x pa-detailIcon"></i>
                    </div>
                </div>
                <div class="pa-detailInfo">
                    <h5 class="font-600">
                        {{ partnerAdmin.email.length > 25 ? `${partnerAdmin.email.slice(0,25)}...` : partnerAdmin.email }}
                    </h5>
                </div>
            </div>
            <div class="pa-details mb-3 mx-2">
                <div class="pa-detailIconContainer me-3">
                    <div class="pa-detailIconWrapper">
                        <i class="fas fa-phone-alt fa-x pa-detailIcon"></i>
                    </div>
                </div>
                <div class="pa-detailInfo">
                    <h5 class="font-600">{{ partnerAdmin.phone ? partnerAdmin.phone : ''}}</h5>
                </div>
            </div>
            <div class="pa-details mb-3 mx-2">
                <div class="pa-detailIconContainer me-3">
                    <div class="pa-detailIconWrapper">
                        <i class="fas fa-globe-americas fa-x pa-detailIcon"></i>
                    </div>
                </div>
                <div class="pa-detailInfo">
                    <h5 class="font-600">{{partnerAdmin.country ? partnerAdmin.country.emoji : ""}} &nbsp;
                        {{partnerAdmin.country ? partnerAdmin.country.name : ""}}</h5>
                </div>
            </div>
            <div class="pa-details mx-2">
                <div class="pa-detailIconContainer me-3">
                    <div class="pa-detailIconWrapper">
                        <i class="fas fa-clock fa-x pa-detailIcon"></i>
                    </div>
                </div>
                <div class="pa-detailInfo">
                    <h5 class="font-600">{{partnerAdmin.created_at ? localDateTime(partnerAdmin.created_at) : ''}}
                    </h5>
                </div>
            </div>
        </div>
    </div>

    <!------------- PARTNER ADMIN DETAIL PART END ------------->


    <!------------- PARTNER ADMIN'S PARTNERS DETAILS CARD START ------------->

    <div class="pa-cardTitleContainer mb-3 mx-4" v-if="partnerAdmin.partners_count">
        <div class="pa-validationIconWrapper me-3">
            <div class="pa-IconContainer">
                <i class="fas fa-users pa-cardIcon"></i>
            </div>
        </div>
        <h5 class="pa-cardTitle mb-0">
            Partners ({{ partnerAdmin.partners_count }})
        </h5>
    </div>

    <div class="card card-style" v-if="partnerAdmin.partners_count">
        <div class="content">
            <div class="pa-details mb-3 mx-2" v-for="record in partnerAdmin.partners">
                <div class="pa-locationDetailIconContainer me-3">
                    <div class="pa-locationDetailIconWrapper">
                        <i class="fas fa-building fa-x pa-locationDetailIcon"></i>
                    </div>
                </div>
                <div class="pa-detailInfo">
                    <h5>
                        <span class="color-theme text-capitalize">{{record.business_name + (record.business_name ? " - " : "")}}</span>
                        <span class="text-capitalize">({{record.full_name}})</span>
                    </h5>
                </div>
            </div>
        </div>
    </div>

    <!------------- PARTNER ADMIN'S PARTNERS DETAILS CARD END ------------->

</div>